import React from 'react';
import { SEO } from '../components/SEO';
import { StaticImage } from 'gatsby-plugin-image';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Layout } from '../layouts/Layout';
import classNames from 'classnames';

const Profile = ({
  basic = false,
  title = '병원장',
  name = '권기민',
  img = (
    <StaticImage
      src="../resources/profile-kkm.png"
      className="w-full h-auto"
      objectFit="cover"
      objectPosition="center"
      placeholder="none"
      alt="hero"
      backgroundColor="transparent"
    />
  ),
  details = [
    '아주대학교 의과대학 졸업',
    '아주대학교병원 직업환경의학과 전문의',
    '한신메디피아 진료과장',
    '평택굿모닝병원 진료과장',
  ],
}) => {
  return (
    <div className={classNames('w-full overflow-hidden p-4', basic && 'bg-white')}>
      <div className={classNames('relative w-full h-auto overflow-hidden', basic ? 'min-h-[320px]' : 'min-h-[460px]')}>
        {!basic && (
          <svg
            className="absolute bottom-0 left-0 right-0 w-full h-auto"
            viewBox="0 0 335 277"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#93887C" fill-rule="evenodd" fill-opacity=".4">
              <path d="M137.5 1C213.44 1 275 62.56 275 138.5S213.44 276 137.5 276C63.096 276 2.495 216.903.075 143.087l-.075.078V3.178l124.43-1.565A139.2 139.2 0 0 1 137.5 1z" />
              <path d="m229.083 35.5.528.558A111.506 111.506 0 0 0 226 36c-60.199 0-109 48.577-109 108.5S165.801 253 226 253c59.276 0 107.501-47.1 108.966-105.753l.034.04V36.824L229.084 35.5z" />
            </g>
          </svg>
        )}
        <div className="absolute bottom-0 left-0 right-0 w-full max-w-[299px] m-auto">{img}</div>
        {!basic && (
          <div className="absolute flex flex-col left-4 top-1/3">
            <span className="font-light text-[18px]">{title}</span>
            <span className="text-3xl font-extrabold">{[...(name as any)].join(' ')}</span>
          </div>
        )}
      </div>
      <div className="bg-white shadow-xl text-gray-800 p-5 mb-5 flex flex-col text-[16px]">
        <div className="flex items-end mb-3 text-lg">
          {title} <h1 className="ml-1 text-2xl font-extrabold">{[...(name as any)].join(' ')}</h1>
        </div>
        {details.map((text, idx) => (
          <p key={idx} className="mt-1">
            {text}
          </p>
        ))}
      </div>
    </div>
  );
};

const Section = ({ className = '', children }) => {
  return (
    <div
      className={classNames(
        'relative flex flex-col overflow-x-hidden items-center justify-center w-full m-auto text-white max-w-screen-sm mx-auto',
        className
      )}
    >
      {children}
    </div>
  );
};

export default function DoctorsPage(props) {
  return (
    <Layout className="relative bg-slate">
      <SEO title={null} titleTemplate="" />
      <Header noHeight className="sticky top-0 left-0 right-0 z-10" />
      <Section>
        <Profile
          title="병원장"
          name="권기민"
          img={
            <StaticImage
              src="../resources/profile-kkm.png"
              className="w-full h-auto ml-6"
              objectFit="cover"
              objectPosition="center"
              placeholder="none"
              alt="hero"
              backgroundColor="transparent"
            />
          }
          details={[
            '아주대학교 의과대학 졸업',
            '아주대학교병원 직업환경의학과 전문의',
            '한신메디피아 진료과장',
            '평택굿모닝병원 진료과장',
          ]}
        />
      </Section>
      <Section>
        <Profile
          basic
          title="내과 과장"
          name="심재두"
          img={
            <StaticImage
              src="../resources/Doctor03_Sim.png"
              className="w-full h-auto"
              objectFit="cover"
              objectPosition="center"
              placeholder="none"
              alt="hero"
              backgroundColor="transparent"
            />
          }
          details={[
            '경희대학교 의과대학 졸업',
            '경희대학교병원 내과 전문의',
            '늘봄요양병원 내과과장, 투석담당',
            '굿모닝요양병원 내과과장, 투석담당',
            '하워드힐요양병원 내과과장, 투석담당',
            '베스트원요양병원 내과과장',
            '샬롬클리닉 원장',
          ]}
        />
      </Section>
      <Section>
        <Profile
          basic
          title="재활의학과 과장"
          name="주혜인"
          img={
            <StaticImage
              src="../resources/Doctor04_Ju.png"
              className="w-full h-auto"
              objectFit="cover"
              objectPosition="center"
              placeholder="none"
              alt="hero"
              backgroundColor="transparent"
            />
          }
          details={[
            '이화여자 의과대학 졸업',
            '국립재활원 재활의학과 전문의',
            '강동경희대병원 전임의',
            '강남구립행복요양병원 진료과장',
          ]}
        />
      </Section>
      <Section>
        <Profile
          basic
          title="신경과 과장"
          name="정재경"
          img={
            <StaticImage
              src="../resources/Doctor05_Jeong.png"
              className="w-full h-auto"
              objectFit="cover"
              objectPosition="center"
              placeholder="none"
              alt="hero"
              backgroundColor="transparent"
            />
          }
          details={['강원대학교 의학전문대학원 졸업', '강원대학교병원 신경과 전문의']}
        />
      </Section>
      <Section>
        <Profile
          basic
          title="신장내과 과장"
          name="이승찬"
          img={
            <StaticImage
              src="../resources/Doctor06_Lee.png"
              className="w-full h-auto"
              objectFit="cover"
              objectPosition="center"
              placeholder="none"
              alt="hero"
              backgroundColor="transparent"
            />
          }
          details={[
            '영남대학교 의과대학 졸업',
            '분당제생병원 내과전공의',
            '분당제생병원 신장내과 전임의',
            '대한신장학회 정회원',
            '대한투석협회 정회원',
            '투석전문의',
          ]}
        />
      </Section>
      <Section>
        <Profile
          basic
          title="한방과장"
          name="김영선"
          img={
            <StaticImage
              src="../resources/Doctor07_Kim.png"
              className="w-full h-auto"
              objectFit="cover"
              objectPosition="center"
              placeholder="none"
              alt="hero"
              backgroundColor="transparent"
            />
          }
          details={[
            '대전대 한의학과 졸업',
            '원광노인전문병원 한방과장',
            '시흥참사랑요양병원 한방과장',
            '시흥 s&k 요양병원 한방과장',
            '의령군립노인전문병원 한방과장',
            '경남도립노인전문병원 한방과장',
          ]}
        />
      </Section>
      <Footer />
    </Layout>
  );
}
